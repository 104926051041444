import DOMPurify from "dompurify";
import parse from "html-react-parser";

export const highlightText = (text, searchQuery) => {
  if (!searchQuery) return text
  const regex = new RegExp(`(${searchQuery})`, 'gi')
  return text?.replace(regex, '<mark>$1</mark>')
}
export const parseHtmlSanitizeAddTargetToLinks = (content, searchQuery) =>
  parse(DOMPurify.sanitize(highlightText(content, searchQuery)), {
    replace: (domNode) => {
      // Handle <a> tags with href starting with '#', internal links: : <sup><a href='#bookmark1'>[1]</a></sup>, convention to create internal page links:
      // expectedFrom: <sup><a class='s15' href='#bookmark1'>[1]</a></sup>         class='s15' class attribute is optional
      // expectedTo: <strong><sup><a name='bookmark1'>[1]</a></sup></strong>  strong attribute  if you want to keep bold text
//      if (
//        domNode?.name === 'a' &&
//        domNode.attribs &&
//        domNode.attribs.href?.startsWith('#')
//      ) {
//        return (
//          <a className={domNode.attribs.class} href={domNode.attribs.href}>
//            {domNode.children?.map((child) => child?.data || '')}
//          </a>
//        )
//      }

      // Handle <a> tags with target="_blank", links which we want to open in new tab
//      if (
//        domNode?.name === 'a' &&
//        domNode.attribs &&
//        domNode.attribs.target === '_blank'
//      ) {
//        return (
//          <a
//            href={domNode.attribs.href}
//            target='_blank'
//            rel='noopener noreferrer'
//          >
//            {domNode.children?.map((child) => child?.data || '')}
//          </a>
//        )
//      }

      // Handle <a name="bookmark1">...</a> by replacing `name` with `id`, handling referced links <strong><sup><a name='bookmark1'>[1]</a></sup></strong>
//      if (domNode?.name === 'a' && domNode.attribs?.name) {
//        return (
//          <a id={domNode.attribs.name}>
//            {domNode.children?.map((child) => child?.data || '')}
//          </a>
//        )
//      }
      // Handle the specific case of <sup><a ...></a></sup> or <strong><sup><a ...></a></sup>
      // if (
      //   domNode?.name === 'sup' ||
      //   domNode?.name === 'strong' ||
      //   domNode?.name === 'a'
      // ) {
      //   return (
      //     <domNode.type {...domNode.attribs}>
      //       {domNode.children?.map((child, idx) => (
      //         <child.type key={idx} {...child.attribs}>
      //           {child.data ||
      //             child.children?.map((grandChild) => grandChild.data)}
      //         </child.type>
      //       ))}
      //     </domNode.type>
      //   )
      // }

      // Default handling for <a> tags without target="_blank" or special cases
      if (domNode?.name === 'a' && domNode.attribs) {
        return (
          <a
            href={domNode.attribs.href}
            className={domNode.attribs.class}
            id={domNode.attribs.id}
            rel="noopener noreferrer"
            target={domNode.attribs.target || undefined}
          >
            {domNode.children?.map((child) => child?.data || "")}
          </a>
        );
      }
    },
  })

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
