// Better react component: here we are not duplicating T&C content, instead re using TermsAndConditions component
import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Slide,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'
import TermsAndConditions from './TermsAndConditions' // Import the component

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const WarningPopup = () => {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('paper')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const descriptionElementRef = useRef(null)
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit')
    if (!firstVisit) {
      setOpen(true)
      localStorage.setItem('firstVisit', 'true')
    }
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  // Ensure the dialog does not close unless the "I, Agree" button is clicked
  const handleClose = (event, reason) => {
    // another reason for dialogBox to get close Parent Component Update: If the parent component’s state changes in a way that affects the dialog’s open prop value.
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return // Prevent closing when clicking the backdrop or pressing the escape key
    }
    setOpen(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose} // Custom onClose logic
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        {/* <DialogTitle id='scroll-dialog-title'>Terms and Conditions</DialogTitle> */}
        <DialogContent dividers={scroll === 'paper'}>
          {/* Render TermsAndConditions component instead of raw HTML */}
          <div
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TermsAndConditions />
            {/* Display the full terms and conditions component */}
          </div>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant='contained'
            autoFocus
            onClick={handleClose}
            startIcon={<ThumbUpAltRoundedIcon />}
            sx={{
              color: '#fff',
              bgcolor: '#232536',
              mr: 2,
              fontWeight: 500,
              ':hover': {
                color: '#232536',
                bgcolor: '#ffcf51',
              },
            }}
          >
            I, Agree
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='success'
          sx={{ width: '100%' }}
        >
          Terms accepted. You can now access the website.
        </Alert>
      </Snackbar>
    </>
  )
}

export default WarningPopup
// import React, { useState, useEffect, useRef } from 'react'
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   DialogContentText,
//   Button,
//   Snackbar,
//   Alert,
//   Slide,
// } from '@mui/material'
// import { useLocation } from 'react-router-dom'
// import { styled } from '@mui/material/styles'
// import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'
// // import TCData from '../termsAndCondition'
// // import './WarningPopUp.css'
// const StyledButton = styled(Button)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   '&:hover': {
//     backgroundColor: theme.palette.primary.dark,
//   },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: theme.palette.primary.dark,
//   },
//   textTransform: 'none', // Ensure the button text is not transformed
// }))
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />
// })
// const WarningPopup = () => {
//   const [open, setOpen] = useState(false)
//   const [scroll, setScroll] = useState('paper')
//   const [snackbarOpen, setSnackbarOpen] = useState(false)
//   const descriptionElementRef = useRef(null)
//   const [fullWidth, setFullWidth] = React.useState(true)
//   const [maxWidth, setMaxWidth] = React.useState('md')
//   // const termsList = [
//   //   {
//   //     title: 'Prohibited Activities',
//   //     subItems: [
//   //       'Engage in any activity that could infringe on the intellectual property rights of others.',
//   //       'Use automated systems, such as bots or scripts, to access the Website or its contents for any purpose.',
//   //       'Engage in any form of hacking, data mining, reproducing content of the website or other activities intended to damage, disrupt or clone the functionality of the Website.',
//   //     ],
//   //   },
//   //   {
//   //     title: 'Contact Information',
//   //     subItems: [
//   //       'For any questions, concerns, or clarifications regarding these Terms, please contact us at contact@GCCTaxLaws.com.',
//   //     ],
//   //   },
//   // ]
//   // const location = useLocation()
//   useEffect(() => {
//     // If you want the pop-up to show every time a new session starts, use sessionStorage
//     const firstVisit = localStorage.getItem('firstVisit') //  localStorage to track if a user is visiting for the first time
//     if (!firstVisit) {
//       setOpen(true)
//       localStorage.setItem('firstVisit', 'true')
//     }
//     // If you want to show the pop-up on every visit, just remove the localStorage check. "setOpen(true);"
//     if (open) {
//       const { current: descriptionElement } = descriptionElementRef
//       if (descriptionElement !== null) {
//         descriptionElement.focus()
//       }
//     }
//   }, [open]) // add location in dependency array, to show the pop-up every time the route changes

//   // const handleClose = () => {
//   //   setOpen(false)
//   //   setSnackbarOpen(true) // Show the snackbar on button click
//   // }
//     const handleClose = (event, reason) => {
//       if (
//         reason &&
//         (reason === 'backdropClick' || reason === 'escapeKeyDown')
//       ) {
//         return // Prevent closing when clicking the backdrop or pressing the escape key
//       }
//       setOpen(false)
//       setSnackbarOpen(true)
//     }
//   const handleSnackbarClose = (event, reason) => {
//     if (reason === 'clickaway') return
//     setSnackbarOpen(false)
//   }
//   const handleMaxWidthChange = (event) => {
//     setMaxWidth(
//       // @ts-expect-error autofill of arbitrary value is not handled.
//       event.target.value
//     )
//   }

//   const handleFullWidthChange = (event) => {
//     setFullWidth(event.target.checked)
//   }
//   // Why I put my file in public folder? If I build my project with jsonfile in src folder, my file will be include in the generated main.js by the command npm run build. useEffect, fetch async-await

//   // const renderList = (items, parentType = 'ol') => {
//   //   const isOrderedList = parentType === 'ol'

//   //   return React.createElement(
//   //     isOrderedList ? 'ol' : 'ul',
//   //     {
//   //       style: isOrderedList
//   //         ? { listStyleType: 'none', paddingLeft: '20px' }
//   //         : { listStyleType: 'none' },
//   //     },
//   //     items.map((item, index) => (
//   //       <li key={index} data-list-text={item.dataListText || ''}>
//   //         {item.text && <p>{item.text}</p>}
//   //         {item.strongText && <strong>{item.strongText}</strong>}
//   //         {item.isLink && (
//   //           <a href={item.link} style={{ marginLeft: '5px' }}>
//   //             {item.text}
//   //           </a>
//   //         )}
//   //         {item.children && renderList(item.children, item.listType || 'ol')}
//   //       </li>
//   //     ))
//   //   )
//   // }

//   // const TermsList = () => <>{renderList(TCData)}</>
//   return (
//     <>
//       <Dialog
//         open={open}
//         TransitionComponent={Transition}
//         keepMounted
//         fullWidth={fullWidth}
//         maxWidth={maxWidth}
//         onClose={handleClose}
//         scroll={scroll} // Enable scroll
//         disableBackdropClick // Prevents closing when clicking outside the dialog
//         disableEscapeKeyDown // Prevents closing with the escape key
//         aria-labelledby='scroll-dialog-title'
//         aria-describedby='scroll-dialog-description'
//       >
//         <DialogTitle id='scroll-dialog-title'>Terms and Conditions</DialogTitle>
//         {/* <DialogTitle>Route Changed!</DialogTitle> */}
//         <DialogContent dividers={scroll === 'paper'}>
//           <DialogContentText
//             id='scroll-dialog-description'
//             ref={descriptionElementRef}
//             tabIndex={-1}
//             component='div' // Allow rich content like paragraphs, lists, and links
//             sx={{ padding: '20px' }}
//           >
//             <p sx={{ p: 2 }}>
//               Welcome to GCCTaxLaws.com (the "Website"). These Terms and
//               Conditions ("Terms") govern your access to and use of the Website,
//               which provides access to tax laws and related information in the
//               GCC region. By accessing or using the Website, you agree to be
//               bound by these Terms.
//               <strong>
//                 If you do not agree to these Terms, you must discontinue using
//                 the Website immediately
//               </strong>
//               .
//             </p>
//             {/* <TermsList /> */}

//             <ol sx={{ p: 2 }}>
//               <li data-list-text='1.'>
//                 <p>Nature of Content</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The Website offers access to English translations of GCC
//                       tax laws originally enacted in Arabic. These translations
//                       are for informational purposes only and do not constitute
//                       official or legally binding versions of the laws.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Some translations are those released by the government (to
//                       the extent available in the public domain), while others
//                       may be privately sourced.
//                     </p>
//                   </li>
//                   <li data-list-text='c.'>
//                     <p>
//                       The original Arabic texts of the laws shall always prevail
//                       in the event of any discrepancy, inconsistency, or
//                       conflict between the Arabic original and the English
//                       translation. Users are advised to consult the original
//                       Arabic texts for authoritative guidance.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='2.'>
//                 <p>Disclaimer of Warranty</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The Website and its content, including but not limited to
//                       the translations provided, are endeavoured to be offered
//                       on an "as is", "as available" and "best effort" basis,
//                       without any warranties of any kind, either express or
//                       implied.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Legal and regulatory changes may occur frequently, and
//                       updates to the content on the Website may not always
//                       reflect the latest changes in law or practice.
//                       GCCTaxLaws.com does not guarantee that the content on the
//                       Website, including translations, is complete, accurate,
//                       current, or error-free.
//                     </p>
//                   </li>
//                   <li data-list-text='c.'>
//                     <p>
//                       Users are solely responsible for cross-verifying the
//                       information obtained from the Website with official
//                       sources, including consulting original legal texts,
//                       government publications, or professional advisors to
//                       ensure that no discrepancies exist and that the
//                       information is up to date and applicable to their
//                       particular situation. GCCTaxLaws.com assumes no liability
//                       for any actions taken or decisions made based on the
//                       information provided.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='3.'>
//                 <p>Use of the Website</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       You may use the Website solely for personal,
//                       informational, and non-commercial purposes.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       You agree not to engage in any activity that could
//                       disrupt, disable, overburden, or impair the proper
//                       functioning of the Website.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='4.'>
//                 <p>No Legal Advice</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The information and content provided on the Website,
//                       including translations of GCC tax laws, do not constitute
//                       legal advice. The Website is a resource for general
//                       information, and not a substitute for professional legal
//                       counsel.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Users are responsible for obtaining appropriate legal
//                       advice before making any legal, financial, or tax-related
//                       decisions based on the information provided by the
//                       Website.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='5.'>
//                 <p>Limitation of Liability</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       To the fullest extent permitted by law, GCCTaxLaws.com,
//                       its owners, group entities, affiliates, managers,
//                       officers, directors, employees, and agents, shall not be
//                       liable for any direct, indirect, incidental,
//                       consequential, special, punitive, or exemplary damages
//                       resulting from or in connection with your access to or use
//                       of the Website.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       This includes, but is not limited to, any loss of profits,
//                       data, business, or other intangible losses, even if
//                       GCCTaxLaws.com has been advised of the possibility of such
//                       damages.
//                     </p>
//                   </li>
//                   <li data-list-text='c.'>
//                     <p>
//                       You agree that you will access and use the Website and its
//                       content at your own risk. GCCTaxLaws.com, its owners,
//                       group entities, affiliates, managers, officers, directors,
//                       employees, and agents make no guarantees regarding the
//                       accuracy or reliability of the Website's content.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='6.'>
//                 <p>Indemnification</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       You agree to indemnify, defend, and hold harmless
//                       GCCTaxLaws.com, its owners, group entities, affiliates,
//                       managers, officers, directors, employees, and agents from
//                       any claims, liabilities, damages, losses, costs, or
//                       expenses (including attorneys' fees) arising out of or
//                       related to your use of the Website, your violation of
//                       these Terms, or your breach of any third-party rights.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='7.'>
//                 <p>Compliance with GCC Laws</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       GCCTaxLaws.com is committed to complying with all
//                       applicable laws and regulations within the GCC.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Users agree to access and use the Website in compliance
//                       with all relevant laws and regulations of the GCC region.
//                       Any misuse of the information on the Website in violation
//                       of local, national, or international laws is strictly
//                       prohibited.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='8.'>
//                 <p>Changes to Terms and Conditions</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       GCCTaxLaws.com reserves the right to modify these Terms at
//                       any time, without prior notice. Any changes will become
//                       effective upon posting the revised Terms on the Website.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Users are responsible for reviewing these Terms regularly.
//                       Continued use of the Website following any changes
//                       constitutes your acceptance of the revised Terms.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='9.'>
//                 <p>Governing Law and Jurisdiction</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       These Terms are governed by and construed in accordance
//                       with the laws of UAE, without regard to its conflict of
//                       law provisions.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Any disputes arising out of or related to these Terms or
//                       your use of the Website shall be subject to the exclusive
//                       jurisdiction of the courts in Dubai.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='10.'>
//                 <p>Severability</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       If any provision of these Terms is held to be invalid or
//                       unenforceable, the remaining provisions shall remain in
//                       full force and effect.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='11.'>
//                 <p>Entire Agreement</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       These Terms, together with any other legal notices or
//                       policies posted on the Website, constitute the entire
//                       agreement between you and GCCTaxLaws.com regarding your
//                       use of the Website.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='12.'>
//                 <p>Privacy Policy</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       By using the Website, you agree to GCCTaxLaws.com's
//                       collection, use, and storage of your information in
//                       accordance with its Privacy Policy.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       The Privacy Policy explains how we collect, use, and
//                       protect your personal data and can be accessed [here].
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='13.'>
//                 <p>Termination of Use</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       GCCTaxLaws.com reserves the right to terminate or suspend
//                       your access to the Website at its sole discretion, without
//                       notice, for conduct that it believes violates these Terms
//                       or is harmful to other users or the Website itself.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='14.'>
//                 <p>User-Generated Content</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       By submitting any content (such as comments, feedback, or
//                       posts) to the Website, you grant GCCTaxLaws.com a
//                       worldwide, non-exclusive, royalty-free, perpetual, and
//                       irrevocable right to use, modify, distribute, and display
//                       such content in connection with the Website's operation.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Users are solely responsible for the content they post and
//                       agree not to submit any content that is unlawful,
//                       offensive, defamatory, or infringing on the rights of
//                       others. GCCTaxLaws.com reserves the right to remove any
//                       content at its discretion.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='15.'>
//                 <p>Third-Party Links</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The Website may contain links to third-party websites or
//                       services that are not owned or controlled by
//                       GCCTaxLaws.com.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       GCCTaxLaws.com has no control over and assumes no
//                       responsibility for the content, privacy policies, or
//                       practices of any third-party websites or services. Users
//                       access such third-party content at their own risk.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='16.'>
//                 <p>Cookies and Tracking Technologies</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The Website may use cookies or similar technologies to
//                       improve user experience, analyze site traffic, and
//                       personalize content. By continuing to use the Website, you
//                       consent to the use of such technologies in accordance with
//                       our [Cookie Policy].
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='17.'>
//                 <p>Modification of Content</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       GCCTaxLaws.com reserves the right to modify, update, or
//                       remove any content on the Website at its sole discretion
//                       without prior notice.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='18.'>
//                 <p>Feedback and Suggestions</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       Any feedback, suggestions, or ideas you provide to
//                       GCCTaxLaws.com in connection with your use of the Website
//                       shall be deemed non-confidential and may be used by
//                       GCCTaxLaws.com without any obligation to compensate you.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       GCCTaxLaws.com is free to use any feedback for any
//                       purpose, including improving the Website and its services.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='19.'>
//                 <p>User Account and Security</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       Certain features of the Website may require users to
//                       create an account. You are responsible for maintaining the
//                       confidentiality of your account credentials and for all
//                       activities that occur under your account.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       GCCTaxLaws.com reserves the right to terminate or suspend
//                       accounts that violate these Terms or engage in fraudulent
//                       activities.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='20.'>
//                 <p>Beta Services</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       From time to time, GCCTaxLaws.com may offer beta services
//                       or features on the Website. These beta services are
//                       provided "as-is" and without any warranties.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       GCCTaxLaws.com reserves the right to discontinue or modify
//                       beta services at any time without prior notice.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='21.'>
//                 <p>Notices</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       Any notices or other communications required or permitted
//                       under these Terms shall be in writing and delivered by
//                       email to contact@GCCTaxLaws.com. You agree that any notice
//                       from GCCTaxLaws.com will be considered received by you
//                       once it is sent to the email address associated with your
//                       account.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='22.'>
//                 <p>Advertising and Promotions</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       The Website may contain advertisements or promotions for
//                       third-party products and services. GCCTaxLaws.com does not
//                       endorse or guarantee the accuracy or reliability of any
//                       such advertisements or promotions.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Any transactions or interactions with advertisers or
//                       promoters are solely between you and the advertiser, and
//                       GCCTaxLaws.com is not responsible for any issues arising
//                       from such interactions.
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//               <li data-list-text='23.'>
//                 <p>
//                   Users agree not to use the Website to (Prohibited Activities):
//                 </p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       Engage in any activity that could infringe on the
//                       intellectual property rights of others.
//                     </p>
//                   </li>
//                   <li data-list-text='b.'>
//                     <p>
//                       Use automated systems, such as bots or scripts, to access
//                       the Website or its contents for any purpose.
//                     </p>
//                   </li>
//                   <li data-list-text='c.'>
//                     <p>
//                       Engage in any form of hacking, data mining, reproducing
//                       content of the website or other activities intended to
//                       damage, disrupt or clone the functionality of the Website.
//                     </p>
//                   </li>
//                 </ol>
//                 <p>
//                   <strong>
//                     GCCTaxLaws.com reserves the right to take appropriate
//                     action, including legal remedies, in response to any
//                     prohibited activities.
//                   </strong>
//                 </p>
//               </li>
//               <li data-list-text='24.'>
//                 <p>Contact Information</p>
//                 <ol style={{ listStyleType: 'lower-alpha' }}>
//                   <li data-list-text='a.'>
//                     <p>
//                       For any questions, concerns, or clarifications regarding
//                       these Terms, please contact us at
//                       <a href='mailto:contact@GCCTaxLaws.com'>
//                         contact@GCCTaxLaws.com
//                       </a>
//                       .
//                     </p>
//                   </li>
//                 </ol>
//               </li>
//             </ol>
//           </DialogContentText>
//         </DialogContent>
//         {/* <DialogContent>
//         <p>Thank you for visiting our website for the first time!</p>
//         <p>You have navigated to a new page: {location.pathname}</p>
//       </DialogContent> */}
//         <DialogActions>
//           <Button
//             variant='contained'
//             autoFocus
//             onClick={handleClose}
//             startIcon={<ThumbUpAltRoundedIcon />}
//             sx={{
//               color: '#fff',
//               bgcolor: '#232536',
//               mr: 2,
//               fontWeight: 500,
//               ':hover': {
//                 color: '#232536',
//                 bgcolor: '#ffcf51', // theme.palette.primary.main - primary.main
//               },
//             }}
//           >
//             I, Agree
//           </Button>
//           {/* <StyledButton onClick={handleClose}>I, understand</StyledButton> */}
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={3000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity='success'
//           sx={{ width: '100%' }}
//         >
//           Terms accepted. You can now access the website.
//         </Alert>
//       </Snackbar>
//     </>
//   )
// }

// export default WarningPopup
