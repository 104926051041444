import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  useMediaQuery,useTheme
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import CustomRadio from "./CustomRadio";
import CustomFormControl from "./CustomFormControl";
import CustomTextField from "./CustomTextField";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const SideBar = ({
  selectedCountry,
  setSelectedCountry,
  selectedLawShortName,
  setSelectedLawShortName,
  selectedOption,
  setSelectedOption,
  searchQuery,
  setSearchQuery,
  searchParams,
  setSearchParams,
  // handleSearchClick,
  // resetFilters,
  // resetTrigger,
  // setResetTrigger,
  // handleOptionChange,
  handleReset,
  handleSearch,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [countries, setCountries] = useState([]);
  const [laws, setLaws] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");

  const disableLawsDropdown = () => {
    if (selectedCountry === "") return { disabled: true };
    return {};
  };
  // const disableSearchBtn = () => {
  //   if (selectedCountry === "" && selectedLawShortName === "")
  //     return { disabled: true };
  //   return {};
  // };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const _handleReset = useCallback(() => {
    handleReset();
  }, [handleReset]);

  const _handleSearch = useCallback(() => {
    handleSearch();
  }, [handleSearch]);

  const handleSearchButtonClick = () => {
    _handleSearch();
    // setSnackbarMessage(`Searching "${searchQuery}" in "${selectedLawShortName} Law"`);
    setSnackbarOpen(true);
  };
  // https://www.npmjs.com/package/axios#handling-errors
  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios
        .get(`${baseUrl}/api/v1/countries/`)
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      setCountries(response?.data);
      setLaws(
        response?.data?.filter(
          (country) =>
            country?.countryName?.toLowerCase() ===
            selectedCountry?.toLowerCase()
        )[0]?.laws
      );
    };
    fetchCountries();
  }, []);

  // useEffect(() => {
  //   if (selectedCountry) {
  //     const fetchLaws = async () => {
  //       const response = await axios.get(
  //         `${baseUrl}/api/v1/laws/country?countryName=${selectedCountry}`
  //       );
  //       setLaws(response.data);
  //     };
  //     // setLaws(countries?.filter((country)=>country.countryName===selectedCountry)[0].laws)
  //     fetchLaws();
  //   } else {
  //     setLaws([]);
  //   }
  // }, [ selectedCountry]);

  return (
    <Box
      sx={{
        width: isSmallScreen ? '100%' : '300px',
        padding: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        borderRight: '1px solid #ccc',
        margin: 2,
        // minHeight: "100vh"
      }}
    >
      <Typography variant='h4'>Filters</Typography>
      <CustomFormControl fullWidth margin='normal'>
        <InputLabel shrink={selectedCountry !== ''}>
          Select Country
        </InputLabel>
        <Select
          value={selectedCountry}
          onChange={(e) => {
            setSelectedCountry(e.target.value)
            setLaws(
              countries?.filter(
                (country) =>
                  country.countryName.toLowerCase() ===
                  e.target.value.toLowerCase()
              )[0]?.laws
            )
          }}
          label='Select Country' // Match this with InputLabel
        >
          <MenuItem value=''>Select Country</MenuItem>
          {countries?.map((country) => (
            <MenuItem key={country.id} value={country.countryName}>
              {country.countryName}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
      <CustomFormControl fullWidth margin='normal'>
        <InputLabel shrink={selectedLawShortName !== ''}>Select Law</InputLabel>
        <Select
          value={selectedLawShortName}
          onChange={(e) => setSelectedLawShortName(e.target.value)}
          {...disableLawsDropdown()}
          label='Select Law' // Match this with InputLabel
        >
          <MenuItem value=''>Select Law</MenuItem>
          {laws?.map((law) => (
            <MenuItem key={law.id} value={law.lawShortName}>
              {law.lawFullName}
            </MenuItem>
          ))}
          <MenuItem value='vat'>
            VAT Law (Federal Decree-Law No 8 of 2017)
          </MenuItem>
        </Select>
      </CustomFormControl>
      <FormControl component='fieldset' fullWidth margin='normal'>
        <Typography variant='subtitle1'>View as list of</Typography>
        <RadioGroup
          value={selectedOption}
          // onChange={handleOptionChange}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <FormControlLabel
            value='articles'
            control={<CustomRadio />}
            label='Articles'
          />

          <FormControlLabel
            value='chapters'
            control={<CustomRadio />}
            label='Chapters'
          />
        </RadioGroup>
      </FormControl>
      <CustomTextField
        label='Enter Search Query'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin='normal'
      />
      <Box display='flex' justifyContent='space-between' mt={2}>
        <Button
          variant='contained'
          onClick={handleSearchButtonClick}
          startIcon={<SearchRoundedIcon />}
          // {...disableSearchBtn()}
          sx={{
            color: '#fff',
            bgcolor: '#232536',
            mr: 2,
            fontWeight: 500,
            ':hover': {
              color: '#232536',
              bgcolor: '#ffcf51', // theme.palette.primary.main - primary.main
            },
          }}
        >
          Search
        </Button>
        <Button
          variant='outlined'
          onClick={_handleReset}
          startIcon={<RestartAltRoundedIcon />}
          sx={{
            color: '#232536',
            bgcolor: '#fff',
            borderColor: '#232536',
            mr: 2,
            fontWeight: 500,
            ':hover': {
              color: '#232536',
              bgcolor: '#ffcf51', // theme.palette.primary.main - primary.main
              borderColor: '#232536',
            },
          }}
        >
          Reset
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        //   message={snackbarMessage}
        message={`Searching "${searchQuery}" in "${selectedLawShortName} Law"`}
        autoHideDuration={3000}
      />
    </Box>
  )
};
export default SideBar;
