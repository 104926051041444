// src/pages/Homepage.js
import React,{useEffect} from 'react'
import HeroSection from '../components/HeroSection'
import ExpertCorner from '../components/ExpertCorner'
import GCCTaxNews from '../components/GCCTaxNews'
import PopularTools from '../components/PopularTools'
import PopularDownloads from '../components/PopularDownloads'
import CommunityForum from '../components/CommunityForum'
import { Box, Grid } from '@mui/material'
import ReactGA from 'react-ga4'
import usePageTracking from '../../../services/usePageTracking'
const Homepage = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/',
  //     title: 'Home',
  //   })
  // })
  return (
    <Box>
      <HeroSection />
      {/* Expecting to release on 1 Aug 2024 with very few features - Law database
      only, so commenting out below working code of ExpertCorner, GCCTaxNews,
      PopularTools, PopularDownloads, CommunityForum
      <Box sx={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExpertCorner />
          </Grid>
          <Grid item xs={12} md={6}>
            <GCCTaxNews />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "row",
            gap: 1,
            gridTemplateColumns: "repeat(4, 1fr)",
            // gridTemplateRows: "repeat(2,1fr)",
            gridTemplateRows: "auto",

            gridTemplateAreas: `"popularTools communityForum communityForum communityForum"
                                "popularDownloads communityForum communityForum communityForum"`,
          }}
        >
          <Box sx={{ gridArea: "popularTools", gridRow: "1", gridColumn: "1" }}>
            <PopularTools />
          </Box>
          <Box
            sx={{ gridArea: "popularDownloads", gridRow: "2", gridColumn: "1" }}
          >
            <PopularDownloads />
          </Box>
          <Box
            sx={{
              gridArea: "communityForum",
              gridRow: "span 2",
              gridColumn: "span 3",
            }}
          >
            <CommunityForum />
          </Box>
        </Box>
      </Box> */}
    </Box>
  )
}

export default Homepage
