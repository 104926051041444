import React from 'react'
import { Typography, Box,Divider } from '@mui/material'
import './NestedOL2.css'
import {Link} from 'react-router-dom'
const TermsAndConditions = () => {
  return (
     <Box
      sx={{
        padding: { xs: '20px', md: '30px' },
        maxWidth: { xs: '100%', md: '800px' },
        margin: '0 auto',
      }}
    >
      <Typography variant='h4' gutterBottom>
        Terms and Conditions
      </Typography>
      <Divider sx={{ backgroundColor: 'white', mb: 2,borderBottomWidth: '2px',borderRadius: '5px' }} />
      <Typography variant='body1' gutterBottom>
        Welcome to GCCTaxLaws.com (the "Website"). These Terms and Conditions
        ("Terms") govern your access to and use of the Website, which provides
        access to tax laws and related information in the GCC region. By
        accessing or using the Website, you agree to be bound by these Terms.
        <strong>
          If you do not agree to these Terms, you must discontinue using the
          Website immediately.
        </strong>
      </Typography>
      <ol className='custom-list'>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Nature of Content
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The Website offers access to English translations of GCC tax
                laws originally enacted in Arabic. These translations are for
                informational purposes only and do not constitute official or
                legally binding versions of the laws.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Some translations are those released by the government (to the
                extent available in the public domain), while others may be
                privately sourced.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                The original Arabic texts of the laws shall always prevail in
                the event of any discrepancy, inconsistency, or conflict between
                the Arabic original and the English translation. Users are
                advised to consult the original Arabic texts for authoritative
                guidance.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Disclaimer of Warranty
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The Website and its content, including but not limited to the
                translations provided, are endeavoured to be offered on an "as
                is", "as available" and "best effort" basis, without any
                warranties of any kind, either express or implied.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Legal and regulatory changes may occur frequently, and updates
                to the content on the Website may not always reflect the latest
                changes in law or practice. GCCtaxlaws.com does not guarantee
                that the content on the Website, including translations, is
                complete, accurate, current, or error-free.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Users are solely responsible for cross-verifying the information
                obtained from the Website with official sources, including
                consulting original legal texts, government publications, or
                professional advisors to ensure that no discrepancies exist and
                that the information is up to date and applicable to their
                particular situation. GCCtaxlaws.com assumes no liability for
                any actions taken or decisions made based on the information
                provided.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Use of the Website
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                You may use the Website solely for personal, informational, and
                non-commercial purposes.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                You agree not to engage in any activity that could disrupt,
                disable, overburden, or impair the proper functioning of the
                Website.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            No Legal Advice
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The information and content provided on the Website, including
                translations of GCC tax laws, do not constitute legal advice.
                The Website is a resource for general information, and not a
                substitute for professional legal counsel.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Users are responsible for obtaining appropriate legal advice
                before making any legal, financial, or tax-related decisions
                based on the information provided by the Website.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Limitation of Liability
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                To the fullest extent permitted by law, GCCtaxlaws.com, its
                owners, group entities, affiliates, managers, officers,
                directors, employees, and agents, shall not be liable for any
                direct, indirect, incidental, consequential, special, punitive,
                or exemplary damages resulting from or in connection with your
                access to or use of the Website.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                This includes, but is not limited to, any loss of profits, data,
                business, or other intangible losses, even if GCCtaxlaws.com has
                been advised of the possibility of such damages.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                You agree that you will access and use the Website and its
                content at your own risk. GCCtaxlaws.com, its owners, group
                entities, affiliates, managers, officers, directors, employees,
                and agents make no guarantees regarding the accuracy or
                reliability of the Website's content.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Indemnification
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                You agree to indemnify, defend, and hold harmless
                GCCtaxlaws.com, its owners, group entities, affiliates,
                managers, officers, directors, employees, and agents from any
                claims, liabilities, damages, losses, costs, or expenses
                (including attorneys' fees) arising out of or related to your
                use of the Website, your violation of these Terms, or your
                breach of any third-party rights.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Compliance with GCC Laws
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com is committed to complying with all applicable
                laws and regulations within the GCC.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Users agree to access and use the Website in compliance with all
                relevant laws and regulations of the GCC region. Any misuse of
                the information on the Website in violation of local, national,
                or international laws is strictly prohibited.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Changes to Terms and Conditions
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com reserves the right to modify these Terms at any
                time, without prior notice. Any changes will become effective
                upon posting the revised Terms on the Website.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Users are responsible for reviewing these Terms regularly.
                Continued use of the Website following any changes constitutes
                your acceptance of the revised Terms.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Governing Law and Jurisdiction
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                These Terms are governed by and construed in accordance with the
                laws of UAE, without regard to its conflict of law provisions.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Any disputes arising out of or related to these Terms or your
                use of the Website shall be subject to the exclusive
                jurisdiction of the courts in Dubai.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Severability
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                If any provision of these Terms is held to be invalid or
                unenforceable, the remaining provisions shall remain in full
                force and effect.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Entire Agreement
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                These Terms, together with any other legal notices or policies
                posted on the Website, constitute the entire agreement between
                you and GCCtaxlaws.com regarding your use of the Website.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Privacy Policy
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                By using the Website, you agree to GCCtaxlaws.com's collection,
                use, and storage of your information in accordance with its
                Privacy Policy.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                The Privacy Policy explains how we collect, use, and protect
                your personal data and can be accessed
                <Typography
                  component={Link}
                  to='/privacy-policy'
                  variant='body2'
                  sx={{
                    // mb: 1,
                    textAlign: 'left',
                    ':hover': {
                      backgroundColor: '#ffcf51',
                    },
                    color: '#232536',
                    textDecoration: 'none',
                    fontWeight: '600',
                    borderRadius: '4px',
                    padding: '0 2px',
                    display: 'inline-block',
                  }}
                >
                  here
                </Typography>
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Termination of Use
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com reserves the right to terminate or suspend your
                access to the Website at its sole discretion, without notice,
                for conduct that it believes violates these Terms or is harmful
                to other users or the Website itself.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            User-Generated Content
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                By submitting any content (such as comments, feedback, or posts)
                to the Website, you grant GCCtaxlaws.com a worldwide,
                non-exclusive, royalty-free, perpetual, and irrevocable right to
                use, modify, distribute, and display such content in connection
                with the Website's operation.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Users are solely responsible for the content they post and agree
                not to submit any content that is unlawful, offensive,
                defamatory, or infringing on the rights of others.
                GCCtaxlaws.com reserves the right to remove any content at its
                discretion.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Third-Party Links
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The Website may contain links to third-party websites or
                services that are not owned or controlled by GCCtaxlaws.com.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com has no control over and assumes no responsibility
                for the content, privacy policies, or practices of any
                third-party websites or services. Users access such third-party
                content at their own risk.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Cookies and Tracking Technologies
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The Website may use cookies or similar technologies to improve
                user experience, analyze site traffic, and personalize content.
                By continuing to use the Website, you consent to the use of such
                technologies in accordance with our{' '}
                <Typography
                  component={Link}
                  to='/cookie-policy'
                  variant='body2'
                  sx={{
                    // mb: 1,
                    textAlign: 'left',
                    ':hover': {
                      backgroundColor: '#ffcf51',
                    },
                    color: '#232536',
                    textDecoration: 'none',
                    fontWeight: '600',
                    borderRadius: '4px',
                    padding: '0 2px',
                    display: 'inline-block',
                  }}
                >
                  Cookie Policy
                </Typography>
                .
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Modification of Content
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com reserves the right to modify, update, or remove
                any content on the Website at its sole discretion without prior
                notice.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Feedback and Suggestions
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                Any feedback, suggestions, or ideas you provide to
                GCCtaxlaws.com in connection with your use of the Website shall
                be deemed non-confidential and may be used by GCCtaxlaws.com
                without any obligation to compensate you.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com is free to use any feedback for any purpose,
                including improving the Website and its services.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            User Account and Security
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                Certain features of the Website may require users to create an
                account. You are responsible for maintaining the confidentiality
                of your account credentials and for all activities that occur
                under your account.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com reserves the right to terminate or suspend
                accounts that violate these Terms or engage in fraudulent
                activities.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Beta Services
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                From time to time, GCCtaxlaws.com may offer beta services or
                features on the Website. These beta services are provided
                "as-is" and without any warranties.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                GCCtaxlaws.com reserves the right to discontinue or modify beta
                services at any time without prior notice.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Notices
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                Any notices or other communications required or permitted under
                these Terms shall be in writing and delivered by email to
                contact@gcctaxlaws.com. You agree that any notice from
                GCCtaxlaws.com will be considered received by you once it is
                sent to the email address associated with your account.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Advertising and Promotions
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                The Website may contain advertisements or promotions for
                third-party products and services. GCCTaxLaws.com does not
                endorse or guarantee the accuracy or reliability of any such
                advertisements or promotions.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                Any transactions or interactions with advertisers or promoters
                are solely between you and the advertiser, and GCCTaxLaws.com is
                not responsible for any issues arising from such interactions.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Prohibited Activities
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                Users agree not to use the Website to:
              </Typography>
              <ol>
                <li>
                  <Typography variant='body2' gutterBottom>
                    Engage in any activity that could infringe on the
                    intellectual property rights of others.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    Use automated systems, such as bots or scripts, to access
                    the Website or its contents for any purpose.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    Engage in any form of hacking, data mining, reproducing
                    content of the website or other activities intended to
                    damage, disrupt, or clone the functionality of the Website.
                  </Typography>
                </li>
              </ol>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>
                  GCCTaxLaws.com reserves the right to take appropriate action,
                  including legal remedies, in response to any prohibited
                  activities.
                </strong>
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Contact Information
          </Typography>
          <Typography variant='body2' gutterBottom>
            For any questions, concerns, or clarifications regarding these
            Terms, please contact us at
            <Typography
              component={Link}
              to='mailto:contact@GCCTaxLaws.com'
              variant='body2'
              sx={{
                // mb: 1,
                textAlign: 'left',
                ':hover': {
                  // color: '#232536',
                  backgroundColor: '#ffcf51',
                },
                color: '#232536',
                textDecoration: 'none',
                fontWeight: '600',
                borderRadius: '4px',
                padding: '0 2px',
                // color: 'white',
                display: 'inline-block',
              }}
            >
              contact@gcctaxlaws.com
            </Typography>
            .
          </Typography>
        </li>
      </ol>
    </Box>
  )
}

export default TermsAndConditions
