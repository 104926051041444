import React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: '#232536',
  '&.Mui-checked': {
    color: '#232536',
  },
  '&.MuiRadio-root:hover': {
    backgroundColor: 'rgba(255, 207, 81, 0.2)', // translucent shade of #ffcf51
  },
}));

export default CustomRadio;