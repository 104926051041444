// src/components/HeroSection.js
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Grid,
  Snackbar,
} from '@mui/material'
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const focusColor = "#232536";
const theme = createTheme({
  palette: {
    primary: {
      main: focusColor,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .Mui-focused": {
            color: focusColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          textDecoration: "none", 
          // TODO: still we can see homepage search form mai label strikethrough, try to use Sidebar component form theme
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: focusColor,
          "&.Mui-checked": {
            color: focusColor,
          },
        },
      },
    },
  },
});
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const HeroSection = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [country, setCountry] = useState('UAE')
  const [law, setLaw] = useState('CIT')
  const [searchQuery, setSearchQuery] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [termsClicked, setTermsClicked] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true) // becuase default value of country and law is UAE and CIT, so useEffect will setIsButtonDisabled(false)
  // const [isButtonDisabled, setIsButtonDisabled] = useState(!(country && law && acceptedTerms && termsClicked));

  const navigate = useNavigate()

  useEffect(() => {
    setIsButtonDisabled(
      !(
        (country && law)
        // && acceptedTerms && termsClicked
      )
    )
  }, [
    country,
    law,
    // , acceptedTerms
  ])

  const handleSearch = () => {
    // console.log('Button clicked');
    // console.log('country:', country);
    // console.log('law:', law);
    // console.log('acceptedTerms:', acceptedTerms);
    // console.log('termsClicked:', termsClicked);
    // console.log(country,law,acceptedTerms,termsClicked);
    if (law === 'VAT') {
      setOpenSnackbar(true) // Show snackbar if law is VAT
      return
    }
    if (
      country &&
      law
      // && acceptedTerms && termsClicked
    ) {
      navigate(`/search?country=${country}&law=${law}&query=${searchQuery}`)
    }
  }
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url('/heroSectionBG.png')`,
        // backgroundImage: `url('https://images.unsplash.com/photo-1489410342162-b4b300a0bb15?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,

        backgroundSize: 'cover', // cover- image keeps its aspect ratio and fills the given dimension. The image will be clipped to fit and ensures the image covers the entire Box, none-The image is not resized
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents repeating the image

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 5%',
        color: '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={isSmallScreen ? 'h4' : 'h2'}>
            Your go to tax research platform
          </Typography>
          <Typography variant={isSmallScreen ? 'subtitle1' : 'h5'}>
            {/* <Typography variant={isSmallScreen?"body1":"h5"}> */}A platform
            for tax advisors, corporations, academic institutions seeking tax
            laws, news & insights.
          </Typography>
          {/* <Button variant="contained" color="primary">Explore</Button> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: '20px',
              borderRadius: '8px',
              color: '#000',
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography variant='h6'>Enter Your Search Query</Typography>
              <FormControl fullWidth margin='normal'>
                <InputLabel required>Select Country</InputLabel>
                <Select
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value)
                    // setLaws(
                    //   countries?.filter(
                    //     (country) =>
                    //       country.countryName.toLowerCase() ===
                    //       e.target.value.toLowerCase()
                    //   )[0]?.laws
                    // );
                  }}
                  label='Country'
                  required
                >
                  {/* TODO: fetch this data from DB don't hardcode, take reference from Sidebar */}
                  <MenuItem value=''>Select Country</MenuItem>

                  {['UAE'].map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin='normal'>
                <InputLabel required>Select Law</InputLabel>
                <Select
                  value={law}
                  onChange={(e) => setLaw(e.target.value)}
                  // TODO: disable law drop down if no country is selected

                  label='Law'
                  required
                >
                  {/*  TODO: fetch this data from DB don't hardcode, take reference from Sidebar */}
                  <MenuItem value=''>Select Law</MenuItem>
                  {[
                    'CIT - Corporate Tax Law'
                    // , 'VAT - Value Added Tax Law'
                  ]?.map((law) => (
                    <MenuItem
                      key={law}
                      value={law.includes(' - ') ? law.split(' - ')[0] : law}
                    >
                      {law.includes(' - ') ? law.split(' - ')[1] : law}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label='Search Query'
                margin='normal'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              {/* <Box display='flex' alignItems='center' margin='normal'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                      // required
                    />
                  }
                  label={
                    <Typography variant='body2'>
                      I hereby accept the
                      <a
                        href='#'
                        // href="/terms-and-conditions" target='_blank' rel='noopener noreferrer'
                        onClick={() => setTermsClicked(true)}
                        style={{ cursor: 'pointer' }}
                      >
                        T&C
                      </a>
                      of GCCTaxLaws.com
                    </Typography>
                  }
                />
              </Box> */}

              <Button
                // fullWidth
                variant='contained'
                onClick={handleSearch}
                startIcon={<SearchRoundedIcon />}
                disabled={isButtonDisabled}
                sx={{
                  color: '#fff',
                  bgcolor: '#232536',
                  mt: 2,
                  mr: 2,
                  fontWeight: 500,
                  ':hover': {
                    color: '#232536',
                    bgcolor: '#ffcf51', // theme.palette.primary.main - primary.main
                  },
                }}
              >
                Search
              </Button>
            </ThemeProvider>
            {/* <Link to={`/articles/${article.id}`}>{contentItem.text}</Link>; */}
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Hide after 3 seconds
        onClose={() => setOpenSnackbar(false)}
        message='Stay tuned!! Our team is working on it. 🙂'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  )
};

export default HeroSection;
