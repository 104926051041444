import React, { useMemo } from "react";
import {
  Box,
  Pagination as MuiPagination,
  useMediaQuery,useTheme
} from "@mui/material";

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage]);
    return (
      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        <MuiPagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => paginate(page)}
          variant="outlined"
          shape="rounded"
          size={isSmallScreen?"small":"medium"}
        />
      </Box>
    );
  };
  
  export default Pagination