import React from 'react'
import { Box, Typography,Divider } from '@mui/material'
import './NestedOL2.css' // Assuming this handles ordered list styles
import { Link } from 'react-router-dom'
const CookiePolicy = () => {
  return (
    <Box
      sx={{
        padding: { xs: '20px', md: '30px' },
        maxWidth: { xs: '100%', md: '800px' },
        margin: '0 auto',
      }}
    >
      <Typography variant='h4' gutterBottom>
        Cookie Policy
      </Typography>
      <Divider
        sx={{
          backgroundColor: 'white',
          mb: 2,
          borderBottomWidth: '2px',
          borderRadius: '5px',
        }}
      />
      <Typography variant='body1' gutterBottom>
        This Cookie Policy explains how GCCTaxLaws.com (the "Website") uses
        cookies and similar technologies to recognize you when you visit our
        Website and to provide you with a more personalized experience.
      </Typography>

      {/* Ordered List with custom styles */}
      <ol className='custom-list'>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            What Are Cookies?
          </Typography>
          <Typography variant='body2' gutterBottom>
            Cookies are small text files placed on your device that help us
            understand your preferences and enhance your experience on our
            Website. They enable us to remember your actions and preferences
            over time.
          </Typography>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Types of Cookies We Use
          </Typography>
          <ol>
            <li>
              <Typography variant='body1' gutterBottom>
                <strong>Essential Cookies:</strong> These cookies are necessary
                for the operation of our website. They enable you to navigate
                our website and use its features, such as accessing secure
                areas.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <strong>Analytics Cookies:</strong> These cookies allow us to
                track and analyze how visitors interact with our website. This
                helps us understand and improve the performance of our website.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <strong>Functionality Cookies:</strong> These cookies enable our
                website to remember choices you make (such as your acceptance to
                terms & Conditions or language preferences) and provide enhanced
                features.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <strong>Advertising Cookies:</strong> These cookies are used to
                deliver relevant advertisements to you based on your interests
                and browsing habits.
              </Typography>
            </li>
          </ol>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Third-Party Cookies
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may also use third-party cookies provided by trusted third-party
            service providers. These cookies may collect information about your
            online activities over time and across different websites.
          </Typography>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Managing Cookies
          </Typography>
          <Typography variant='body2' gutterBottom>
            You can control and manage cookies in various ways, including by
            adjusting your browser settings. Please note that disabling cookies
            may affect the functionality of our website and your user
            experience.
          </Typography>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Cookie Consent
          </Typography>
          <Typography variant='body2' gutterBottom>
            By using our website, you consent to the placement of cookies on
            your device as described in this Cookie Policy. If you do not agree
            to the use of cookies, please refrain from using our website.
          </Typography>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Changes to Cookie Policy
          </Typography>
          <Typography variant='body2' gutterBottom>
            We reserve the right to update or change this Cookie Policy at any
            time. Any changes will be effective immediately upon posting the
            updated Cookie Policy on this page.
          </Typography>
        </li>

        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Contact Us
          </Typography>
          <Typography variant='body2' gutterBottom>
            If you have any questions or concerns regarding this Cookie Policy,
            please contact us at
            
            <Typography
              component={Link}
              to='mailto:contact@GCCTaxLaws.com'
              variant='body2'
              sx={{
                // mb: 1,
                textAlign: 'left',
                ':hover': {
                  backgroundColor: '#ffcf51',
                },
                color: '#232536',
                textDecoration: 'none',
                fontWeight: '600',
                borderRadius: '4px',
                padding: '0 2px',
                display: 'inline-block',
              }}
            >
              contact@gcctaxlaws.com
            </Typography>
            .
          </Typography>
        </li>
      </ol>
    </Box>
  )
}

export default CookiePolicy
