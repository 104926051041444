import axios from 'axios'
import {v4 as uuidv4} from 'uuid'

axios.interceptors.request.use(
  (config) => {
    // const correlationId = new CorrelationIdService().generateCorrelationId;
    const correlationId = `${Date.now()}-gccTaxLaws-ui`;
    config.headers["X-Correlation-ID"] = correlationId;
//    config.headers["Client-ID"] = "your-client-id"; // add clientId as well
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// const EnvironmentHelper={
//   isLocal:()=>{
//     return !process.env.NODE_ENV || process.env.NODE_ENV==='dev'
//   }
// }

// class CorrelationIdService{
//   generateCorrelationId(){
//     // if we have any complex logic, its better to segregate that
//     const unixTime=Date.now()
//     return `${unixTime}-gccTaxLaws-ui` // replace with actual logic to generate correlationId
//   }
// }

// if(EnvironmentHelper.isLocal()){
//   // apply additional interceptors for dev env
//   axios.interceptors.request.use((config) => {
//     config.headers['User-Header']='user-header-value'
//     return config
//   },(error)=>{
//     return Promise.reject(error)
//   });
// }else{
//   // apply additional interceptors for prod env
//   axios.interceptors.request.use((config)=>{
//     config.timeout=5000 // example timout interceptor
//     return config
//   },(error)=>{
//     return Promise.reject(error)
//   })
// }