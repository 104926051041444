import React from 'react';
import { Snackbar } from '@mui/material';

const CustomSnackbar = ({ open, onClose, message, autoHideDuration }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      message={message}
      autoHideDuration={autoHideDuration}
      sx={{
        // background: 'linear-gradient(135deg, #323232 0%, #4a4a4a 100%)',
        backgroundColor: '#4a4a4a', // Slightly lighter than #323232
        color: '#ffffff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        // border: '1px solid #FF9800',
        // padding: '12px 24px',
        fontSize: '20px',
        borderRadius: '8px',
        zIndex: 9999,
        mt: 7,
        // mr: 1,
        animation: 'slideInRight 0.5s ease-out',
        '@keyframes slideInRight': {
          '0%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
      }}
    />
  )
};

export default CustomSnackbar;
