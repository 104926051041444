import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { parseHtmlSanitizeAddTargetToLinks } from "../services/utils";
const CustomTable = ({ data, searchQuery }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {data?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row?.map((cell, cellIndex) => (
              <TableCell
                key={cellIndex}
                // dangerouslySetInnerHTML={{
                //   __html: DOMPurify.sanitize(highlightText(cell, searchQuery)),
                // }}
              >
                {parseHtmlSanitizeAddTargetToLinks(cell, searchQuery)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
export default CustomTable;
