import { styled } from '@mui/material/styles';
import { FormControl } from '@mui/material';
const CustomFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputLabel-root': {
      color: '#232536',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#232536',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#232536',
      },
      '&:hover fieldset': {
        borderColor: '#ffbc03', // border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#232536',
      },
    },
    '& .MuiSelect-root': {
      '&:focus': {
        backgroundColor: 'rgba(35, 37, 54, 0.1)', // translucent background on focus
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#232536', // border color when focused
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'rgba(35, 37, 54, 0.1)', // translucent shade on focus
    },
  }));
  
export default CustomFormControl;
