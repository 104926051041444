import { Link } from "react-router-dom";

export const handleEmail = (article) => {
  // Logic to open mail client
  window.location.href = `mailto:?subject=${article.title}&body=Check out this article: ${window.location.href}`;
  // const subject = encodeURIComponent(`Article: ${article.title}`);
  // const body = encodeURIComponent(`Check out this article: ${article.title}`);
  // window.location.href = `mailto:?subject=${subject}&body=${body}`;
};

export const handleOpenInNewTab = (article, slug) => {
  // Don't get confuse by the url of frontend(use to view any article in a new tab), with url of backend (used to fetch any article from DB)
  // Frontend: http://localhost:3000/articles/665f7e40afe8c449ce999c60 appRouter will handle path: '/articles/:id', <ArticleDetail />
  // Backend: http://localhost:8080/api/v1/articles/665f7e40afe8c449ce999c60

  <Link to={`/articles/${article.id}`}>{/* {contentItem.text} */}</Link>;
  // Logic to open the article in a new tab
  // window.open(window.location.href, '_blank');

  // console.log(article);

  // navigate(newTabUrl)
  const newWindow = window.open(slug, "_blank");
  newWindow.focus();

  // history.push(`/article/${article._id}`);
};
