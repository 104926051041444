import ReactGA from 'react-ga4'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchGuideBySlug } from '../../services/articleSevices'
import { Typography, Box } from '@mui/material'
import Error from '../Error'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import usePageTracking from '../../services/usePageTracking'
import GuideArticle from './GuideArticle'
const GuideDetail = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/guides/:slug',
  //     title: 'Specific Guide Detail Page',
  //   })
  // })
  const { slug } = useParams()
  const [guide, setGuide] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  // const sanitizedHTML = (content) =>
  //   parse(DOMPurify.sanitize(content), {
  //     replace: (domNode) => {
  //       if (
  //         domNode?.name === 'a' &&
  //         domNode.attribs
  //         // && domNode.attribs.target === "_blank"
  //       ) {
  //         return (
  //           <a
  //             href={domNode.attribs.href}
  //             target='_blank'
  //             rel='noopener noreferrer'
  //           >
  //             {/* {domNode.children[0].data} */}
  //             {domNode.children?.map((child) => child?.data || '')}
  //           </a>
  //         )
  //       }
  //     },
  //   })

  useEffect(() => {
    const getGuide = async () => {
      try {
        const data = await fetchGuideBySlug(slug)
        setGuide(data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    getGuide()
  }, [slug])

  if (loading) {
    return <Typography>Loading...</Typography>
  }
  if (error) {
    return <Error error={error} />
  }
  return (
    guide && <GuideArticle article={guide}/>
    // (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       justifyContent: 'space-around',
    //       alignItems: 'flex-start',
    //     }}
    //   >
    //     <Typography variant='h5'>{sanitizedHTML(guide.title)}</Typography>

    //     <Box
    //       sx={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'space-around',
    //         alignItems: 'flex-start',
    //       }}
    //     >
    //       {guide?.contents.length !== 0 ? (
    //         guide?.contents?.map((content, index) => (
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               flexDirection: 'column',
    //               justifyContent: 'space-around',
    //               alignItems: 'flex-start',
    //             }}
    //             key={index}
    //           >
    //             <Typography variant='subtitle1'>
    //               {sanitizedHTML(content.indexTitle)}
    //             </Typography>
    //             <Typography variant='subtitle2'>
    //               {sanitizedHTML(content.data)}
    //             </Typography>
    //           </Box>
    //         ))
    //       ) : (
    //         <Typography variant='h6'>
    //           No Content inside this guide....
    //         </Typography>
    //       )}
    //     </Box>
    //   </Box>
    // )
  )
}
export default GuideDetail
// <GuideArticle article={guide}/>

// <Paper sx={{ margin: 2, padding: 2 }} id={`article-${article.id}`}>
//     <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
