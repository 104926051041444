import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;
// const api = axios.create({
//     baseURL: API_URL
// });

// export const searchArticles = (query) => api.get(`/search?query=${query}`);
// export const searchFilteredArticles = (params) => api.get('/search', { params });
// export const autoCompleteTitle = (prefix) => api.get(`/autocomplete?prefix=${prefix}`);

export const fetchArticles = async () => {
  const response = await fetch(API_URL + "/api/v1/articles/");

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
export const fetchArticleById = async (id) => {
  const response = await fetch(`${API_URL}/api/v1/articles/${id}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const searchArticles = async (query) => {
  const response = await fetch(
    `${API_URL}/api/v1/articles/search?query=${encodeURIComponent(query)}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
export const fetchArticleBySlug = async (slug) => {
  const response = await fetch(`${API_URL}/api/v1/articles/slug/${slug}`);
  // if (response.status >= 200 && response.status <= 299) {} SUCCESS
  if (!response.ok) {
    if (response.status === 404)
      throw new Error(
        `404, No such article exists 🤕 \n We're sorry. The Web address you entered is not a functioning page on our site.`
      );
    if (response.status === 500) throw new Error("500, internal server error");
    throw new Error(
      `Network response was not ok, while fetching: "${slug}", HTTP Status ${response.status}: ${response.statusText}`
    );
  }
  return await response.json();
};

export const fetchDecisionBySlug = async (slug) => {
  const response = await fetch(`${API_URL}/api/v1/decisions/slug/${slug}`);
  // if (response.status >= 200 && response.status <= 299) {} SUCCESS
  if (!response.ok) {
    if (response.status === 404)
      throw new Error(
        `404, No such decision exists 🤕 \n We're sorry. The Web address you entered is not a functioning page on our site.`
      );
    if (response.status === 500) throw new Error("500, internal server error");
    throw new Error(
      `Network response was not ok, while fetching: "${slug}", HTTP Status ${response.status}: ${response.statusText}`
    );
  }
  return await response.json();
};
export const fetchGuideBySlug = async (slug) => {
  const response = await fetch(`${API_URL}/api/v1/guides/slug/${slug}`);
  // if (response.status >= 200 && response.status <= 299) {} SUCCESS
  if (!response.ok) {
    if (response.status === 404)
      throw new Error(
        `404, No such guide exists 🤕 \n We're sorry. The Web address you entered is not a functioning page on our site.`
      );
    if (response.status === 500) throw new Error("500, internal server error");
    throw new Error(
      `Network response was not ok, while fetching: "${slug}", HTTP Status ${response.status}: ${response.statusText}`
    );
  }
  return await response.json();
};
