import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  createTheme,
  ThemeProvider,
  Snackbar,
  Alert,
} from '@mui/material'
import axios from 'axios'

import { Link } from 'react-router-dom'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import XIcon from '@mui/icons-material/X'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
const baseUrl = process.env.REACT_APP_BACKEND_URL

const footerTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: 'white',
          },
          '& label.Mui-focused': {
            color: '#ffcf51',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: '#ffcf51',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#ffcf51',
            },
            '&.Mui-focused .MuiInputBase-input': {
              color: '#ffcf51',
            },
            '& .MuiInputBase-input': {
              color: 'white',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#ffcf51',
          },
        },
      },
    },
  },
})
const Footer = () => {
  const [contactUsEmail, setContactUsEmail] = useState('')
  const [contactUsMessage, setContactUsMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }
  const handleContactUsSend = async (event) => {
    event.preventDefault()
    const contactUsFormResponse = {
      userEmail: contactUsEmail,
      message: contactUsMessage,
    }

    try {
      await axios
        .post(`${baseUrl}/api/v1/contactus/`, contactUsFormResponse)
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          console.log(error.config)
          alert('Failed to submit contact us form')
        })
      setContactUsEmail('')
      setContactUsMessage('')
      setSnackbarOpen(true)
    } catch (error) {
      console.error('Error submitting contact us form:', error)
      alert('Failed to submit contact us form')
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#232536',
          color: 'white',
          padding: { xs: '10px', md: '10px 40px' },
          textAlign: 'center',
        }}
      >
        {/* Row 1 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant='h6' sx={{ ml: 2 }}>
            GCC Tax Laws
          </Typography>
          {/* <Box sx={{ mr: 2 }}>
          <IconButton
            aria-label="Follow us on LinkedIn"
            title="Follow us on LinkedIn"
            component={Link}
            to="https://www.linkedin.com/"
            sx={{
              color: "#6d6e76",
              "&:hover": {
                color: "#ffcf51",
              },
            }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            aria-label="Follow us on X"
            title="Follow us on X"
            component={Link}
            to="https://x.com/"
            sx={{
              color: "#6d6e76",
              "&:hover": {
                color: "#ffcf51",
              },
            }}
          >
            <XIcon />
          </IconButton>
          <IconButton
            aria-label="Follow us on Facebook"
            title="Follow us on Facebook"
            component={Link}
            to="https://www.facebook.com/"
            sx={{
              color: "#6d6e76",
              "&:hover": {
                color: "#ffcf51",
              },
            }}
          >
            <FacebookRoundedIcon />
          </IconButton>

          <IconButton
            aria-label="Follow us on Instagram"
            title="Follow us on Instagram"
            component={Link}
            to="https://www.instagram.com/"
            sx={{
              color: "#6d6e76",
              "&:hover": {
                color: "#ffcf51",
              },
            }}
          >
            <InstagramIcon />
          </IconButton>
        </Box> */}
        </Box>

        <Divider sx={{ backgroundColor: 'white', mb: 2 }} />

        {/* Row 2 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            mb: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ flex: '1 1 18%', mr: 2, ml: 2 }}>
            <Typography variant='h6' sx={{ mb: 1, textAlign: 'left' }}>
              ABOUT
            </Typography>
            <Typography variant='body2' sx={{ mb: 1, textAlign: 'justify' }}>
              GCCTaxLaws.com aims to be your go-to platform for referring tax
              laws implemented in the GCC region. We aim to provide reliable
              information on laws and regulations available in the public
              domain, assisting professionals and businesses navigate the GCC
              region's tax landscape with ease of reference.
            </Typography>
          </Box>
          <Box sx={{ flex: '1 1 18%', mr: 2, ml: 2 }}>
            <Typography variant='h6' sx={{ mb: 1, textAlign: 'left' }}>
              DISCLAIMER
            </Typography>
            <Typography variant='body2' sx={{ mb: 1, textAlign: 'justify' }}>
              The content on GCCTaxLaws.com is for general informational
              purposes only and provides unofficial English translations of tax
              laws implemented in the GCC region. It should not be considered
              legal or tax advice. Always consult a qualified tax professional
              before making any decisions based on any information accessed
              through GCCTaxLaws.com.
            </Typography>
          </Box>
          <Box sx={{ flex: '1 1 18%', mr: 2, ml: 2 }}>
            <Typography variant='h6' sx={{ mb: 1, textAlign: 'left' }}>
              TERMS OF USE
            </Typography>

            {/* <Button
            sx={{
              color: "white",
              p: 0,
              display: "block",
              ":hover": {
                color: "#ffcf51",
              },
              textTransform: "capitalize",
              mb: 1,
            }}
          >
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              Terms & Conditions Btn ✔
            </Typography>
          </Button> */}

            <Typography
              component={Link}
              to='/terms-and-conditions'
              variant='body1'
              sx={{
                mb: 1,
                textAlign: 'left',
                ':hover': {
                  color: '#ffcf51',
                },
                textDecoration: 'none',
                color: 'white',
                display: 'block',
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              component={Link}
              to='/privacy-policy'
              variant='body1'
              sx={{
                mb: 1,
                textAlign: 'left',
                ':hover': {
                  color: '#ffcf51',
                },
                textDecoration: 'none',
                color: 'white',
                display: 'block',
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              component={Link}
              to='/cookie-policy'
              variant='body1'
              sx={{
                mb: 1,
                textAlign: 'left',
                ':hover': {
                  color: '#ffcf51',
                },
                textDecoration: 'none',
                color: 'white',
                display: 'block',
              }}
            >
              Cookie Policy
            </Typography>
          </Box>
          <ThemeProvider theme={footerTheme}>
            <Box
              component='form'
              onSubmit={handleContactUsSend}
              sx={{
                flex: '1 1 30%',
                mr: 2,
                ml: 2,
                // 40%
              }}
            >
              <Typography variant='h6' sx={{ textAlign: 'left', mb: 0.6 }}>
                Get in touch...
              </Typography>
              <TextField
                id='contactUsEmail'
                label='Email'
                variant='outlined'
                fullWidth
                value={contactUsEmail}
                // margin="normal"
                onChange={(e) => setContactUsEmail(e.target.value)}
                required
                sx={{
                  backgroundColor: 'rgba(109, 110, 118, 0.2)',
                  borderRadius: '8px',
                  mb: 0.9,
                }}
                inputProps={{
                  pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$',
                }}
              />
              <TextField
                id='contactUsMessage'
                label='Message'
                variant='outlined'
                value={contactUsMessage}
                onChange={(e) => setContactUsMessage(e.target.value)}
                fullWidth
                // margin="normal"
                multiline
                required
                rows={4}
                sx={{
                  mb: 0.7,
                  backgroundColor: 'rgba(108,110,119,255)',
                  borderRadius: '8px',
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type='submit'
                  variant='contained'
                  sx={{
                    color: '#232536',
                    bgcolor: 'white',
                    fontWeight: 700,
                    ':hover': {
                      bgcolor: '#ffcf51', // theme.palette.primary.main - primary.main
                    },
                  }}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </ThemeProvider>
        </Box>

        <Divider sx={{ backgroundColor: 'white', mb: 2 }} />

        {/* Row 3 */}
        <Box sx={{ textAlign: 'left' }}>
          <Typography>&copy; 2024 GCCTaxLaws.com</Typography>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='success'
          sx={{ width: '100%' }}
        >
          Our team will soon get in touch with you!!😄
        </Alert>
      </Snackbar>
    </>
  )
}

export default Footer
