import React, { useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import { handleOpenInNewTab } from "../../services/articleCardHandlers";
import { Box, Button, Typography, Paper, TableRow,useMediaQuery,useTheme } from "@mui/material";
import { parseHtmlSanitizeAddTargetToLinks } from "../../services/utils";
import DecisionArticle from "./DecisionArticle";

// Display name as heading and title as subHeading

const DecisionCard = ({ article, searchQuery }) => {
    const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleModalToggle = useCallback(() => {
    setModalIsOpen((prev) => !prev);
  }, []);
  return (
    <Paper sx={{ margin: 2, padding: 2 }} id={article?.slug}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen?"column": "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            onClick={handleModalToggle}
            sx={{ cursor: "pointer" }}
          >
            {parseHtmlSanitizeAddTargetToLinks(article?.name, searchQuery)}
          </Typography>
          <Typography
            variant="subtitle1"
            onClick={handleModalToggle}
            sx={{ cursor: "pointer" }}
          >
            {parseHtmlSanitizeAddTargetToLinks(article?.title, searchQuery)}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() =>
              handleOpenInNewTab(article, `/decisions/${article?.slug}`)
            }
          >
            <OpenInNewRoundedIcon color="primary" />
          </Button>
        </Box>
      </Box>
      <Modal open={modalIsOpen} onClose={handleModalToggle}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            position: "relative",
            maxHeight: "90vh",
            overflowY: "auto",
            margin: "5vh auto",
            width: isSmallScreen?"90%":"80%",
            
          }}
        >
          <Button
            onClick={handleModalToggle}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 10,
              backgroundColor: "white",
              border: "1px solid",
              padding: "4px 8px",
            }}
          >
            Close
          </Button>
          <DecisionArticle article={article} searchQuery={searchQuery} />
        </Box>
      </Modal>
    </Paper>
  );
};

export default DecisionCard;
