// src/components/NavBar.js
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  // Link,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Drawer,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";

const NavBarDropdown = ({ country, items }) => (
  <>
    <Dropdown
      trigger={
        <Button
          sx={{
            color: "#FFF",
            ml: 2,
            mr: 2,
            ":hover": {
              color: "#ffcf51",
            },
            fontSize: "1rem", // 1.5 rem
          }}
        >
          {country}
        </Button>
      }
      menu={items.map((item) => (
        <DropdownMenuItem
          key={item}
          onClick={() => {
            console.log(`${country} --> ${item}`);
          }}
          component={Link}
          to={`/search/${country}/${item}`}
        >
          {item}
        </DropdownMenuItem>
      ))}
    />
  </>
);
const drawerWidth = 240;
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  //necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const drawerItems = [
    { text: "Laws", link: "/search/UAE/Tax-Laws", icon: <GavelRoundedIcon /> },
    {
      text: "Decisions",
      link: "/search/UAE/Decisions",
      icon: <MailRoundedIcon />,
    },
    {
      text: "Guides",
      link: "/search/UAE/Guides",
      icon: <ArchiveRoundedIcon />,
    },
    // {
    //   text: "Forms",
    //   link: "/search/UAE/E-Forms",
    //   icon: <InsertDriveFileRoundedIcon />,
    // },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);

  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuHover = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };
  const dropdownItems = ["Tax-Laws", "Decisions", "Guides", "E-Forms"];
  return (
    <StyledAppBar position="static" open={drawerOpen}>
      <AppBar position="static">
        <Toolbar sx={{ bgcolor: "#232536" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, display: { xs: "block", md: "none" } }}
            >
              <MenuRoundedIcon />
            </IconButton>

            {/* sx prop is a Material-UI specific prop and works out-of-the-box only with Material-UI components. Since Link from react-router-dom isn't a Material-UI component */}
            <Link
              to="/"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  // flexGrow: 1,
                  "&:hover": {
                    color: "#ffcf51",
                  },
                }}
              >
                UAE Tax Laws
              </Typography>
            </Link>
          </Box>
          {/* 3 level deep menu
         <Dropdown
          trigger={<Button>Insert</Button>}
          menu={[
            <DropdownNestedMenuItem
              label="Image"
              rightIcon={<ArrowRight />}
              menu={[
                <DropdownMenuItem>
                  <Button
                    variant="text"
                    component="label"
                    sx={{
                      color: "#000",
                      textTransform: "none",
                      fontSize: "1rem"
                    }}
                  >
                    Upload from Computer
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/png, image/jpeg"
                      hidden
                      onChange={() => {}}
                    />
                  </Button>
                </DropdownMenuItem>,
                <DropdownMenuItem
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  From URL
                </DropdownMenuItem>
              ]}
            />,
            <DropdownNestedMenuItem
              label="CSV"
              rightIcon={<ArrowRight />}
              menu={[
                <DropdownNestedMenuItem
                  label="As Table"
                  rightIcon={<ArrowRight />}
                  menu={[
                    <DropdownMenuItem>
                      <Button
                        variant="text"
                        component="label"
                        sx={{
                          color: "#000",
                          textTransform: "none",
                          fontSize: "1rem"
                        }}
                      >
                        Upload from Computer
                        <input
                          id="csvInput"
                          type="file"
                          accept={`.csv`}
                          hidden
                          onChange={(e) => {}}
                        />
                      </Button>
                    </DropdownMenuItem>,
                    <DropdownMenuItem
                      onClick={() => {
                        console.log("clicked");
                      }}
                    >
                      From URL
                    </DropdownMenuItem>
                  ]}
                />
              ]}
            />
          ]}
        /> */}

          {/*  instead of clicking on LawDatabase then we need to click on country so to reduce clicks, open menu while hovering, below is the code but we need to remove some bugs it has
        <Button
          onMouseEnter={handleHover}
          sx={{ color: "yellow", ml: 2, mr: 2 }}
        >
          Law Database Hover
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          {["Bahrain", "Kuwait", "Oman", "Qatar", "KSA", "UAE"].map(
            (country) => (
              <MenuItem
                key={country}
                onMouseEnter={handleSubMenuHover}
                onMouseLeave={handleSubMenuClose}
              >
                {country}
                <Menu
                  anchorEl={subMenuAnchorEl}
                  open={Boolean(subMenuAnchorEl)}
                  onClose={handleSubMenuClose}
                  MenuListProps={{ onMouseLeave: handleSubMenuClose }}
                >
                  {["Tax Laws", "Decisions", "Guides", "E-Forms"].map(
                    (item) => (
                      <MenuItem key={item}>{item}</MenuItem>
                    )
                  )}
                </Menu>
              </MenuItem>
            )
          )}
        </Menu>

        <Divider
          style={{ background: "white", width: 1, borderRadius: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
          //  sx={{ height: 40 }}
        /> */}

          {/* Expecting to release on 1 Aug 2024 with very few features - Law database only, so commenting out below working code of navBar 
        <Dropdown
          trigger={
            <Button sx={{ color: "white", ml: 2, mr: 2 }}>Law Database</Button>
          }
          menu={["Bahrain", "Kuwait", "Oman", "Qatar", "KSA", "UAE"].map(
            (country) => (
              <DropdownNestedMenuItem
                label={country}
                rightIcon={<ArrowRight />}
                menu={["Tax-Laws", "Decisions", "Guides", "E-Forms"].map(
                  (item) => (
                    <DropdownMenuItem
                      onClick={() => {
                        console.log(`${country} --> ${item}`);
                      }}
                      component={Link}
                      to={`/search/${country}/${item}`}
                    >{item}
                    </DropdownMenuItem>
                  )
                )}
              />
            )
          )}
        />
        <Divider
          style={{ background: "white", width: 1, borderRadius: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
          //  sx={{ height: 40 }}
        />
        <Button sx={{ color: "white", ml: 2, mr: 2 }}>Tax Community</Button>
        <Divider
          style={{ background: "white", width: 1, borderRadius: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Button sx={{ color: "white", ml: 2, mr: 2 }}>Tax News</Button>
        <Divider
          style={{ background: "white", width: 1, borderRadius: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Button sx={{ color: "white", ml: 2, mr: 2 }}>Tax Tools</Button>
        <Divider orientation="vertical" flexItem />
        <Divider
          style={{ background: "white", width: 1, borderRadius: 2 }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Button sx={{ color: "white", ml: 2, mr: 2 }}>Downloads</Button>
*/}

          {/* Only 2 countries in Navbar */}
          {/* Center the Dropdowns */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {drawerItems.map((item, index) => (
              <>
                <Link
                  to={item.link}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  <Typography
                    // variant="h6"
                    component="div"
                    sx={{
                      // flexGrow: 1,
                      color: "#FFF",
                      ml: 2,
                      mr: 2,
                      "&:hover": {
                        color: "#ffcf51",
                      },
                      fontSize: "1rem",
                    }}
                  >
                    {item.text}
                  </Typography>
                </Link>
                {/* <NavBarDropdown country="UAE" items={dropdownItems} /> */}
                {index < drawerItems.length - 1 && (
                  <Divider
                    style={{ background: "white", width: 1, borderRadius: 2 }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    //  sx={{ height: 40 }}
                  />
                )}
              </>
            ))}
          </Box>

          {/* <Button
            variant="contained"
            component={Link}
            to="/signup"
            sx={{
              color: "#232536",
              bgcolor: "white",
              mr: 2,
              fontWeight: 700,
              ":hover": {
                bgcolor: "#ffcf51", // theme.palette.primary.main - primary.main
              },
              display: { xs: "none", md: "block" },
            }}
          >
            Log In/Sign Up
          </Button> */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftRoundedIcon />
            ) : (
              <ChevronRightRoundedIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {/* <Divider />
        <List>
          {[
            {
              text: "LogIn / SignUp",
              link: "/signup",
              icon: <AccountCircleRoundedIcon />,
            },
          ].map((item, index) => (
            <ListItem
              key={item.text}
              // disablePadding
              // component={Link} to={item.link}
            >
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate(item.link);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        <Divider />
        <List>
          {drawerItems.map((item, index) => (
            <ListItem
              key={item.text}
              // disablePadding
              // component={Link} to={item.link}
            >
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate(item.link);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </StyledAppBar>
  );
};

export default NavBar;
