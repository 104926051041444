import "./NestedOrderedList.css";
import React from "react";
import { parseHtmlSanitizeAddTargetToLinks } from "../services/utils";
const NestedOrderedList = ({ data, level = 0, searchQuery }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }
  return (
    <ol className="custom-list" style={{ paddingLeft: `${level * 20}px` }}>
      {data?.map((item, index) => (
        <li key={index}>
          <>
            {typeof item === "string" ? (
              <span
                style={{ display: "inline" }}
                // dangerouslySetInnerHTML={{
                //   __html: DOMPurify.sanitize(highlightText(item, searchQuery)),
                // }}
              >
                {parseHtmlSanitizeAddTargetToLinks(item, searchQuery)}
              </span>
            ) : (
              <span
              // dangerouslySetInnerHTML={{
              //   __html: DOMPurify.sanitize(
              //     highlightText(item[0], searchQuery)
              //   ),
              // }}
              >
                {parseHtmlSanitizeAddTargetToLinks(item[0], searchQuery)}
              </span>
            )}
          </>
          {Array.isArray(item[1]) && (
            <NestedOrderedList
              data={item[1]}
              level={level + 1}
              searchQuery={searchQuery}
            />
          )}
        </li>
      ))}
    </ol>
  );
};
export default NestedOrderedList;
