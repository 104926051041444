import React, { useState, useCallback } from "react";
import { Box, Button, Typography, Paper,useMediaQuery,useTheme } from "@mui/material";
import { parseHtmlSanitizeAddTargetToLinks } from "../services/utils";
import ArticleCard from "./ArticleCard";
const ChapterCard = ({ chapter, searchQuery }) => {
  
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [showArticles, setShowArticles] = useState(false);
  const toggleShowArticles = useCallback(() => {
    setShowArticles((prev) => !prev);
  }, []);

  return (
    <Paper sx={{ margin: 2, padding: 2 }}>
      <Typography mb={1}
        variant="h6"
        // dangerouslySetInnerHTML={{
        //   __html: DOMPurify.sanitize(
        //     highlightText(chapter.chapterName, searchQuery)
        //   ),
        // }}
      >
        {parseHtmlSanitizeAddTargetToLinks(chapter.chapterName, searchQuery)}
      </Typography>
      <Button variant="contained" onClick={toggleShowArticles}>
        {showArticles ? "Hide Articles" : "Show Articles"}
      </Button>
      {showArticles && (
        <Box mt={2}>
          {chapter?.articles?.length === 0 ? (
            <Typography>No articles found.</Typography>
          ) : (
            chapter?.articles?.map((article) => (
              <ArticleCard
                key={article.id}
                article={article}
                searchQuery={searchQuery}
              />
            ))
          )}
        </Box>
      )}
    </Paper>
  );
};
export default ChapterCard;
