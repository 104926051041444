import ReactGA from 'react-ga4'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDecisionBySlug } from '../../services/articleSevices'
import { Typography, Box } from '@mui/material'
import Error from '../Error'
import DecisionArticle from './DecisionArticle'
import usePageTracking from '../../services/usePageTracking'
const DecisionDetail = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/decisions/:slug',
  //     title: 'Specific Decision Detail Page',
  //   })
  // })
  const { slug } = useParams()
  const [decision, setDecision] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getDecision = async () => {
      try {
        const data = await fetchDecisionBySlug(slug)
        setDecision(data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    getDecision()
  }, [slug])

  if (loading) {
    return <Typography>Loading...</Typography>
  }
  if (error) {
    return <Error error={error} />
  }
  return decision && <DecisionArticle article={decision} />
}
export default DecisionDetail
// <Paper sx={{ margin: 2, padding: 2 }} id={`article-${article.id}`}>
//     <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
