import React, { useState, useCallback } from "react";

// To enable the PrintRoundedIcon button in the ArticleCard component to print the Article component within it, you need to pass a reference of the Article component to the print function. Utilize a ref to get the Article component's HTML content.
// import {useRef} from "react"

// import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
// import MailRoundedIcon from "@mui/icons-material/MailRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import {
  // handleEmail,
  handleOpenInNewTab,
} from "../services/articleCardHandlers";
import {
  Box,
  Button,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
  Modal,
  Alert,
  Snackbar
} from "@mui/material";
import { parseHtmlSanitizeAddTargetToLinks } from "../services/utils";
import Article from "./Article";

const ArticleCard = ({ article, searchQuery }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('') // Feedback submitted successfully 😄
  const [snackbarSeverity, setSnackbarSeverity] = useState('success') // success or error

  // We can't render Snackbar by mentioning it in FeedbackForm component, why?? So Move Snackbar to a Higher Level (Optional): The Snackbar might be constrained by the modal or other parent elements, so one option is to lift it up to a higher level in the React tree. However, if the modal isn't an issue, we can work with what you have and ensure the Snackbar is triggered properly.
  //       <Modal /> present in <ArticleCard /> , and inside Modal we have <Article />, and in Article we have <FeedbackForm /> which is try to render <Snackbar />
  // The modal's rendering behavior could interfere with how the Snackbar is displayed. Snackbars usually render on the top of the DOM tree, and being inside a modal might restrict its visibility due to the modal's context and z-index behavior.

  // const componentRef = useRef();

  const handleModalToggle = useCallback(() => {
    setModalIsOpen((prev) => !prev)
  }, [])
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setSnackbarOpen(true)
  }
  // const handlePrint = useCallback(() => {
  //   console.log("Hello");
  //   if (componentRef.current) {
  //     console.log("World");
  //     const printWindow = window.open("", "_blank");
  //     printWindow.document.write("<html><head><title>GCC Tax Laws</title>");

  //     // Copy all styles from the current document to the print document
  //     const styleSheets = document.styleSheets;
  //     for (let i = 0; i < styleSheets.length; i++) {
  //       if (styleSheets[i].href) {
  //         printWindow.document.write(
  //           `<link rel="stylesheet" type="text/css" href="${styleSheets[i].href}">`
  //         );
  //       } else {
  //         try {
  //           let css = "";
  //           for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
  //             css += styleSheets[i].cssRules[j].cssText;
  //           }
  //           printWindow.document.write(`<style>${css}</style>`);
  //         } catch (e) {
  //           console.warn("Error accessing stylesheet", e);
  //         }
  //       }
  //     }

  //     printWindow.document.write("</head><body>");
  //     printWindow.document.write(componentRef.current.innerHTML);
  //     printWindow.document.write("</body></html>");
  //     printWindow.document.close();
  //     printWindow.print();
  //   }
  // }, [componentRef]);
  return (
    <>
      <Paper sx={{ margin: 2, padding: 2 }} id={`article-${article.id}`}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            flexDirection: isSmallScreen ? 'column' : 'row',
            // margin: isSmallScreen ? 1 : 2,
            // padding: isSmallScreen ? 1 : 2,
          }}
        >
          <Typography
            variant='h6'
            onClick={handleModalToggle}
            sx={{ cursor: 'pointer' }}
            // dangerouslySetInnerHTML={{
            //   __html: DOMPurify.sanitize(
            //     highlightText(article.title, searchQuery)
            //   ),
            // }}
          >
            {parseHtmlSanitizeAddTargetToLinks(article.title, searchQuery)}
          </Typography>
          <Box>
            {/* use handlePrint, but we need to print Article, not ArticleCard so take care. */}
            {/* <Button onClick={handlePrint}>
            <PrintRoundedIcon color="primary" />
          </Button>
          <Button onClick={() => handleEmail(article)}>
            <MailRoundedIcon color="primary" />
          </Button> */}
            <Button
              onClick={() =>
                handleOpenInNewTab(article, `/articles/${article?.slug}`)
              }
            >
              <OpenInNewRoundedIcon color='primary' />
            </Button>
          </Box>
        </Box>
        <Modal open={modalIsOpen} onClose={handleModalToggle}>
          <Box
            sx={{
              padding: 4,
              backgroundColor: 'white',
              position: 'relative',
              maxHeight: '90vh',
              overflowY: 'auto',
              margin: '5vh auto',
              width: isSmallScreen ? '100%' : '80%',
            }}
          >
            <Button
              onClick={handleModalToggle}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 10,
                backgroundColor: 'white',
                border: '1px solid',
                padding: '4px 8px',
              }}
            >
              Close
            </Button>
            <Article
              // ref={componentRef}
              article={article}
              searchQuery={searchQuery}
              showSnackbar={showSnackbar} // Pass snackbar control to Article
            />
          </Box>
        </Modal>
      </Paper>
      {/* Snackbar outside of Modal */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
};

export default ArticleCard;
