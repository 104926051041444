import React from 'react'
import { Box, Typography,Divider } from '@mui/material'
import './NestedOL2.css' // Assuming this CSS handles the ordered list styles

const PrivacyPolicy = () => {
  return (
 <Box
      sx={{
        padding: { xs: '20px', md: '30px' },
        maxWidth: { xs: '100%', md: '800px' },
        margin: '0 auto',
      }}
    >
      <Typography variant='h4' gutterBottom>
        Privacy Policy
      </Typography>
      <Divider
        sx={{
          backgroundColor: 'white',
          mb: 2,
          borderBottomWidth: '2px',
          borderRadius: '5px',
        }}
      />
      <Typography variant='body1' gutterBottom>
        Welcome to GCCTaxLaws.com (the "Website"). This Privacy Policy explains
        how GCCTaxLaws.com ("we," "our," or "us") collects, uses, discloses, and
        safeguards your information when you visit our Website. By using the
        Website, you agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Typography>

      {/* Ordered List with custom styles */}
      <ol className='custom-list'>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Information We Collect
          </Typography>
          <Typography variant='body2' gutterBottom>
            We collect the following types of information:
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Personal Information:</strong> We may collect personal
                information that you voluntarily provide to us when you use the
                Website, such as your name, email address, phone number, and any
                other information you provide through forms or communications.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Usage Data:</strong> We may collect information about
                how you use the Website, including IP addresses, browser types,
                operating systems, referring URLs, pages viewed, and the dates
                and times of visits.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Cookies and Tracking Technologies:</strong> We use
                cookies and other tracking technologies to enhance your
                experience on the Website. Cookies are small files placed on
                your device that help us understand your preferences and improve
                our services.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            How We Use Your Information
          </Typography>
          <Typography variant='body2' gutterBottom>
            We use your information in the following ways:
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>To Provide and Improve Our Services:</strong> We use
                your information to operate and maintain the Website, respond to
                your inquiries, and improve our services.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>To Analyze and Monitor Usage:</strong> We use usage data
                to analyze trends, track user interactions, and enhance the
                functionality of the Website.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Disclosure of Your Information
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may disclose your information in the following circumstances...
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Service Providers:</strong> We may share your
                information with third-party service providers who perform
                services on our behalf, such as hosting, data analysis, and
                email communication. These service providers are contractually
                obligated to protect your information and use it only for the
                purposes for which it was disclosed.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Legal Requirements:</strong> We may disclose your
                information if required to do so by law or in response to valid
                requests by public authorities (e.g., a subpoena or court
                order).
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Business Transfers:</strong> In the event of a merger,
                acquisition, or any other business reorganization, your
                information may be transferred to the acquiring entity as part
                of the transaction.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Data Security
          </Typography>
          <Typography variant='body2' gutterBottom>
            We implement reasonable security measures to protect your
            information from unauthorized access, use, or disclosure. However,
            no security system is impenetrable, and we cannot guarantee the
            security of your information transmitted to or from the Website.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Your Rights
          </Typography>
          <Typography variant='body2' gutterBottom>
            You have the following rights regarding your personal information:
          </Typography>
          <ol>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Access and Correction:</strong> You have the right to
                access and correct your personal information that we hold. You
                may contact us to request access or correction of your
                information.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Data Deletion:</strong> You may request the deletion of
                your personal information, subject to any legal obligations we
                may have to retain it.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                <strong>Opt-Out:</strong> You may opt out of receiving marketing
                communications from us by following the unsubscribe instructions
                in those communications or contacting us directly.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Third-Party Links
          </Typography>
          <Typography variant='body2' gutterBottom>
            The Website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of those websites.
            We encourage you to review the privacy policies of any third-party
            websites you visit.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Children's Privacy
          </Typography>
          <Typography variant='body2' gutterBottom>
            The Website is not intended for use by children under the age of 18.
            We do not knowingly collect personal information from children under
            18. If we become aware that we have collected personal information
            from a child under 18, we will take reasonable steps to delete such
            information.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Changes to this Privacy Policy
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. We
            encourage you to review this Privacy Policy periodically to stay
            informed about how we are protecting your information.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom sx={{ fontWeight: 'bold' }}>
            Contact Us
          </Typography>
          <Typography variant='body2' gutterBottom>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at: contact@gcctaxlaws.com.
          </Typography>
        </li>
      </ol>
    </Box>
  )
}

export default PrivacyPolicy
