import ReactGA from 'react-ga4'
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import axios from 'axios'
import { Box, Container, Typography, Snackbar, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import DecisionSideBar from './DecisionSideBar'
import DecisionCard from './DecisionCard'
import Pagination from '../Pagination'
import usePageTracking from '../../services/usePageTracking'
import CustomSnackbar from '../CustomSnackbar'
const baseUrl = process.env.REACT_APP_BACKEND_URL

// MainContent and App both components are combined as List
const DecisionList = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/search/:country/Decisions',
  //     title: 'Decisions Search',
  //   })
  // })
  const { country, type } = useParams()

  const [selectedCountry, setSelectedCountry] = useState(country)
  const [selectedLawShortName, setSelectedLawShortName] = useState('')
  const [selectedOption, setSelectedOption] = useState(
    'MD - Ministerial Decision'
  )
  const [selectedYear, setSelectedYear] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const [searchParams, setSearchParams] = useState(null)

  const [itemsPerPage, setItemsPerPage] = useState(10) // pageSize
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPageData, setCurrentPageData] = useState([])

  const [resetTrigger, setResetTrigger] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const cachedResponses = useRef({
    'CD - Cabinet Decision': null,
    'FTA - Federal Tax Authority Decision': null,
    'MD - Ministerial Decision': null,
  })

  // const navigate = useNavigate();

  const handleSearch = useCallback(() => {
    if(selectedLawShortName === 'vat') {
      setSnackbarOpen(true) // Show snackbar if law is VAT
      return
    }
    if (selectedCountry && selectedLawShortName) {
      setSearchParams({
        selectedCountry,
        selectedLawShortName,
        selectedOption,
        selectedYear,
        searchQuery,
      })
    }
    setResetTrigger(false)
    setCurrentPage(1)
  }, [
    selectedCountry,
    selectedLawShortName,
    selectedOption,
    selectedYear,
    searchQuery,
  ])

  // we are not using this function, so ignore
  //   const handleOptionChange = (event) => {
  //     setSelectedOption(event.target.value);
  //     setSearchQuery(""); // BUG need to handle if use searched free zone in articles then if he changed the selectedOption to chapters then we should list chapters,  har chapter mai sirf wahi article dikhao jismai free zone aata ho
  //     setResetTrigger(true);
  //     setTimeout(() => setResetTrigger(false), 0);
  //   };
  const handleReset = useCallback(() => {
    const cachedData = cachedResponses.current[selectedOption]
    if (cachedData) {
      setTotalItems(cachedData.totalElements)
      setCurrentPageData(cachedData.content)
      setSelectedYear('')
      setSearchQuery('')
      setCurrentPage(1)
      setSearchParams(null) // Reset the searchParams to initial state,  <---this may lead to bug if you dont want otherfields to be change like law, country and selectedOption
      setResetTrigger(true)
      // setResetTrigger(prev => !prev);
    }
  }, [selectedOption])

  const sideBarProps = useMemo(
    () => ({
      selectedCountry,
      setSelectedCountry,
      selectedLawShortName,
      setSelectedLawShortName,
      selectedOption,
      setSelectedOption,
      selectedYear,
      setSelectedYear,
      searchQuery,
      setSearchQuery,
      //   searchParams,
      //   setSearchParams,
      //   handleOptionChange,
      handleReset,
      handleSearch,
    }),
    [
      selectedCountry,
      selectedLawShortName,
      selectedOption,
      selectedYear,
      searchQuery,
      handleSearch,
      handleReset,
      //   searchParams
    ]
  )
  useEffect(() => {
    const fetchDefaultData = async () => {
      if (selectedCountry && selectedLawShortName) {
        const endpoint = `${baseUrl}/api/v1/decisions/search`
        // https://www.npmjs.com/package/axios#handling-errors
        const response = await axios
          .get(endpoint, {
            params: {
              country: selectedCountry,
              lawShortName: selectedLawShortName,
              type: selectedOption,
              // query: searchQuery, // we are fetching default data, so it should not depend on query
              // year:
              page: currentPage,
              pageSize: itemsPerPage,
            },
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
            }
            console.log(error.config)
          })
        if (response?.data?.content?.length === 0 && currentPage > 1) {
          setSnackbarOpen(true)
          setCurrentPage(1)
          return
        }
        // response structure is { content: [...], totalElements: number }
        setTotalItems(response?.data.totalElements)
        setCurrentPageData(response?.data.content)
        // setCurrentPage(1)
        cachedResponses.current[selectedOption] = response?.data
      }
    }
    if (!searchParams) {
      fetchDefaultData()
    }
  }, [
    selectedCountry,
    selectedOption,
    selectedLawShortName,
    // searchQuery, // we are fetching default data, so it should not depend on query
    searchParams,
    currentPage,
    itemsPerPage,
  ])

  useEffect(() => {
    // if (resetTrigger) {
    //   //   setTotalItems(0);
    //   //   setCurrentPageData([]);
    //   setSearchQuery("");
    //   setCurrentPage(1);
    //   return;
    // }
    const fetchData = async () => {
      if (searchParams) {
        const cachedData = cachedResponses.current[selectedOption]
        if (
          cachedData &&
          searchParams.searchQuery === '' &&
          searchParams.selectedYear === ''
        ) {
          setTotalItems(cachedData.totalElements)
          setCurrentPageData(cachedData.content)
          setCurrentPage(1)
        } else {
          // make sure this api call is not executed unless we know value of law and country, type of decision, becuase otherwise backend will throw HTTP error 500
          const endpoint = `${baseUrl}/api/v1/decisions/search`
          // https://www.npmjs.com/package/axios#handling-errors
          const response = await axios
            .get(endpoint, {
              params: {
                country: searchParams.selectedCountry,
                lawShortName: searchParams.selectedLawShortName,
                type: searchParams.selectedOption,
                year: searchParams.selectedYear,
                query: searchParams.searchQuery,
                page: currentPage, // let say you gave pageNumber=10 in backend request, but while searching only 2 results only for 2 pages
                // you may get error: java.lang.IllegalArgumentException: fromIndex(30) > toIndex(10) so either handle it on backend or by default always display the first page
                // for example, visit: http://localhost:8080/api/v1/articles/search?country=UAE&lawShortName=cit&query=free+zone&page=4&pageSize=10
                pageSize: itemsPerPage,
              },
            })
            .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request)
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
              }
              console.log(error.config)
            })
          if (response?.data?.content?.length === 0 && currentPage > 1) {
            setSnackbarOpen(true)
            setCurrentPage(1)
            return
          }
          setTotalItems(response?.data.totalElements)
          setCurrentPageData(response?.data.content)
          //   setCurrentPage(1); // Reset to first page on new search
          if (
            searchParams.searchQuery === '' &&
            searchParams.selectedYear === ''
          ) {
            cachedResponses.current[selectedOption] = response?.data
          }
        }
      }
    }
    fetchData()
  }, [searchParams, selectedOption, resetTrigger, currentPage, itemsPerPage])

  //   const paginate = useCallback((pageNumber) => {
  //     if (pageNumber < 1 || pageNumber > Math.ceil(totalItems / itemsPerPage)) {
  //         setSnackbarOpen(true);
  //         return;
  //       }

  //       setCurrentPage(pageNumber);
  //   }, [itemsPerPage,totalItems]);
  const paginate = (pageNumber) => {
    // console.log("Clicked on page: " + pageNumber);
    if (pageNumber < 1 || pageNumber > Math.ceil(totalItems / itemsPerPage)) {
      setSnackbarOpen(true)
      return
    }
    setCurrentPage(pageNumber)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }
  return (
    <Container>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={4} lg={3}>  */}

        <Grid item xs={12} sm={6} md={4}>
          <DecisionSideBar {...sideBarProps} />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={8} lg={9}>  */}
        <Grid item xs={12} sm={6} md={8} mt={2} mb={2}>
          <Box mt={2}>
            {/* {(() => {
            if (currentPageData?.length === 0) {
              return (
                <Typography variant="h6">
                  Search something based on Country, Law...
                </Typography>
              );
            } else if (selectedOption === "CD - Cabinet Decision") {
              return currentPageData?.map((cd) => (
                <CDCard key={cd.id} article={cd} searchQuery={searchQuery} />
              ));
            } else if (
              selectedOption === "FTA - Federal Tax Authority Decision"
            ) {
              return currentPageData?.map((fta) => (
                <FTACard key={fta.id} article={fta} searchQuery={searchQuery} />
              ));
            } else if (selectedOption === "MD - Ministerial Decision") {
              return currentPageData?.map((md) => (
                <MDCard key={md.id} article={md} searchQuery={searchQuery} />
              ));
            }
          })()} */}
            {
              // currentPageData?.length === 0 ? (
              //   <Typography variant='h6'>
              //     Search something based on Country, Law...
              //   </Typography>
              // ) : (
              currentPageData?.map((article) => (
                <DecisionCard
                  key={article.id}
                  article={article}
                  searchQuery={searchQuery}
                />
              ))
              // )
            }
          </Box>
          {currentPageData?.length !== 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              paginate={paginate}
            />
          )}
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message='Requested page is not available.'
        autoHideDuration={3000} // 3 seconds
      />
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message='Requested page is not available.'
        autoHideDuration={10000}
      /> */}
    </Container>
  )
}
export default DecisionList
