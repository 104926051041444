import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#232536",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#232536",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#232536",
    },
    "&:hover fieldset": {
      borderColor: "#ffcf51", // border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#232536",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#232536", // border color when focused
  },
  "& .MuiInputBase-input:focus": {
    backgroundColor: "rgba(35, 37, 54, 0.1)", // translucent shade on focus
  },
}));
export default CustomTextField;
